import { createTheme, createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { bindActionCreators } from "redux";
import Routes from "../routes";
import { useThunkDispatch } from "../util/thunk-dispatch";

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#ababab',
            dark: '#d6d6d6',
            main: '#ffffff',
            contrastText: '#000',
        },
        secondary: {
            main: '#387c6c',
            dark: '#fff',
        },
    }
});

const App: React.FC = () => {
    const asyncDispatch = useThunkDispatch();

    return (
        <>
            <ThemeProvider theme={theme}>
                <div>
                    <Routes />
                </div>
            </ThemeProvider>
        </>
    );
}

export default App;